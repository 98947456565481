import dateTimeService from '@/services/cal/dateTimeService.js';
import axios from 'axios';
export default {
  data: () => ({
    taskHistory: false,
    dateTimeService,
    history: []
  }),
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    async open(taskId) {
      this.taskHistory = true;
      await axios.get(`v1/task/history/${taskId}`).then(response => {
        this.history = response.data;
      }).catch(() => {
        this.$toast.error('Der Verlauf des Tasks konnte nicht geladen werden');
      });
    }
  }
};