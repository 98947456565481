import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VContainer, [_c(VContainer, [_c('h1', {
    staticClass: "d-flex justify-start align-center"
  }, [_c(VChip, {
    attrs: {
      "color": "primary mr-2",
      "pill": ""
    }
  }, [_vm._v("# " + _vm._s(_vm.task.lfdnr))]), _vm._v(" " + _vm._s(_vm.task.title) + " "), _c(VBtn, {
    staticClass: "ms-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openTitleDialog
    }
  }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1)], 1), _c('p', {
    staticClass: "text--secondary"
  }, [_vm._v(" erstellt von " + _vm._s(_vm.task.creationUser) + ", am " + _vm._s(_vm.dateTimeService.getShortGermanDate(_vm.task.creationDate)) + " ")]), _c(VDivider, {
    staticClass: "my-3"
  }), _c('RichTextEditor', {
    staticClass: "pa-2",
    attrs: {
      "text": _vm.task.description,
      "placeholder": "Hier Text eingeben",
      "editable": "",
      "optionalEditing": "",
      "editingCancel": ""
    },
    on: {
      "saved": _vm.descriptionEdited
    }
  })], 1), _c(VContainer, {
    staticClass: "pa-4 d-flex justify-space-around"
  }, [_c('span', {
    staticClass: "text-center"
  }, [_c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openStatusPicker
    }
  }, [_vm.task.status.done == false ? _c(VIcon, [_vm._v("mdi-checkbox-blank-circle-outline")]) : _c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-check-circle-outline")])], 1), _c('div', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("Status")])], 1), _c('span', {
    staticClass: "text-center"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "ripple": false
    },
    on: {
      "click": _vm.openDatePicker
    }
  }, [_vm.task.dueDate != null ? _c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-clock")]) : _c(VIcon, [_vm._v("mdi-clock-outline")])], 1), _c('div', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("Datum")])], 1)]), _c(VExpansionPanels, {
    attrs: {
      "accordion": "",
      "multiple": "",
      "flat": ""
    },
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c(VExpansionPanel, [_c(VDivider), _c(VExpansionPanelHeader, {
    staticClass: "text-subtitle-1"
  }, [_c('div', [_c(VIcon, {
    staticClass: "mr-2 mb-1"
  }, [_vm._v("mdi-account-supervisor-circle")]), _c('span', [_vm._v("Verlinkte Elemente")]), _c(VBtn, {
    staticClass: "mb-1 ml-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.openTaskObjects
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)]), _c(VExpansionPanelContent, [_c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [_vm._l(_vm.objects.addressesWithOutDocuments, function (address) {
    return _c(VListItem, {
      key: address.wfCid
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(" " + _vm._s(address.displayName) + " ")])], 1)], 1);
  }), _vm._l(_vm.objects.addresses, function (address) {
    return _c(VListGroup, {
      key: address.wfCid,
      attrs: {
        "prepend-icon": "mdi-account"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(address.displayName))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(address.documents, function (document) {
      return _c(VListItem, {
        key: document.wfcid
      }, [_c(VListItemIcon, {
        staticClass: "d-flex justify-center"
      }, [_c(VIcon, {
        attrs: {
          "small": ""
        }
      }, [_vm._v("mdi-file-document")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(" " + _vm._s(document.displayName) + " ")])], 1)], 1);
    }), 1);
  })], 2)], 1)], 1), _c(VDivider), _c(VExpansionPanel, [_c(VDivider), _c(VExpansionPanelHeader, {
    staticClass: "text-subtitle-1"
  }, [_c('div', [_c(VIcon, {
    staticClass: "mr-2 mb-1"
  }, [_vm._v("mdi-account-tie")]), _c('span', [_vm._v("Bearbeiter")]), _c(VBtn, {
    staticClass: "mb-1 ml-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.openTaskAssigned
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)]), _c(VExpansionPanelContent, [_c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [_vm._l(_vm.task.assignedUsers, function (usr) {
    return _c(VListItem, {
      key: usr.userId
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
  }), _vm._l(_vm.task.assignedWorkGroups, function (grp) {
    return _c(VListGroup, {
      key: grp.code,
      attrs: {
        "prepend-icon": "mdi-account-group"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(grp.name))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(grp.users, function (usr) {
      return _c(VListItem, {
        key: usr.userId
      }, [_c(VListItemIcon, [_c(VIcon, {
        attrs: {
          "small": ""
        }
      }, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
    }), 1);
  })], 2), _vm.showTakeOverButton ? _c('LBAButton', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "buttonType": 'primary'
    },
    on: {
      "click": function ($event) {
        return _vm.takeOverTask();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm._v(" Aufgabe übernehmen ")];
      },
      proxy: true
    }], null, false, 3796784139)
  }) : _vm._e()], 1)], 1), _c(VExpansionPanel, {
    staticClass: "mb-10"
  }, [_c(VDivider), _c(VExpansionPanelHeader, {
    staticClass: "text-subtitle-1"
  }, [_c('div', [_c(VIcon, {
    staticClass: "mr-2 mb-1"
  }, [_vm._v("mdi-comment")]), _c('span', [_vm._v("Kommentare")])], 1)]), _c(VExpansionPanelContent, [_c(VList, [_vm._l(_vm.task.comments, function (com) {
    return _c(VListItem, {
      key: com.lfdnr
    }, [_c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "text-subtitle-2"
    }, [_vm._v(" " + _vm._s(com.userId) + " am " + _vm._s(_vm.dateTimeService.getShortGermanDate(com.creationDate)) + " "), _c(VBtn, {
      staticClass: "ml-3",
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.tryDeleteComment(com.lfdnr);
        }
      }
    }, [com.userId === _vm.user.userId ? _c(VIcon, {
      attrs: {
        "small": "",
        "color": "error"
      }
    }, [_vm._v("mdi-delete")]) : _vm._e()], 1)], 1), _c('RichTextEditor', {
      attrs: {
        "text": com.text,
        "saveId": com.lfdnr,
        "placeholder": "Hier Text eingeben",
        "editable": "",
        "optionalEditing": "",
        "editingCancel": ""
      },
      on: {
        "saved": _vm.commentEdited
      }
    })], 1)], 1);
  }), _c(VListItem, {
    staticClass: "pa-0"
  }, [_c(VListItemContent, [_c(VListItemTitle, {
    staticClass: "text-subtitle-2"
  }, [_vm._v("Neuer Kommentar")]), _c('RichTextEditor', {
    ref: "richTextNewCommentEditor",
    attrs: {
      "text": _vm.newComment,
      "placeholder": "Hier Text eingeben",
      "editable": ""
    }
  }), _c(VBtn, {
    staticClass: "mt-2",
    attrs: {
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": _vm.addComment
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")]), _vm._v("Hinzufügen")], 1)], 1)], 1)], 2)], 1)], 1)], 1), _c(VBottomNavigation, {
    staticClass: "elevation-0 mt-10",
    attrs: {
      "fixed": "",
      "permanent": ""
    },
    model: {
      value: _vm.navigationValue,
      callback: function ($$v) {
        _vm.navigationValue = $$v;
      },
      expression: "navigationValue"
    }
  }, [_c(VBtn, {
    attrs: {
      "navigationValue": "history"
    },
    on: {
      "click": _vm.openTaskHistory
    }
  }, [_c('span', [_vm._v("Verlauf")]), _c(VIcon, [_vm._v("mdi-history")])], 1), _c(VBtn, {
    attrs: {
      "navigationValue": "delete"
    },
    on: {
      "click": _vm.tryDeleteTask
    }
  }, [_c('span', [_vm._v("Löschen")]), _c(VIcon, {
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-delete")])], 1)], 1)], 1), _c('WarningDialog', {
    ref: "warningAddComment",
    on: {
      "warningActionPerformed": _vm.addCommentForce
    }
  }), _c('WarningDialog', {
    ref: "warningDeleteTask",
    on: {
      "warningActionPerformed": _vm.deleteTask
    }
  }), _c('WarningDialog', {
    ref: "warningDeleteComment",
    on: {
      "warningActionPerformed": _vm.deleteComment
    }
  }), _c('StatusDialog', {
    ref: "changeStatusDialog",
    attrs: {
      "task": _vm.task
    },
    on: {
      "actionPerformed": _vm.changeStatus
    }
  }), _c('TaskObjects', {
    ref: "objects",
    on: {
      "save": _vm.saveObjects
    }
  }), _c('TaskAssigned', {
    ref: "assigned",
    attrs: {
      "taskId": _vm.lfdnr
    },
    on: {
      "saved": _vm.saveAssignees
    }
  }), _c('DatePicker', {
    ref: "datePicker",
    on: {
      "datePicked": _vm.changeDueDate
    }
  }), _c('TitleDialog', {
    ref: "changeTitleDialog",
    attrs: {
      "title": _vm.task.title,
      "availableTitles": _vm.availableTitles
    },
    on: {
      "actionPerformed": _vm.changeTitle
    }
  }), _c('TaskHistory', {
    ref: "history"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };