import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": 1000,
      "cancelButton": false
    },
    on: {
      "close": function ($event) {
        _vm.taskHistory = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Task Verlauf ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm.history.length != 0 ? _c('div', [!_vm.isMobile ? _c(VTimeline, {
          attrs: {
            "reverse": ""
          }
        }, _vm._l(_vm.history, function (entry) {
          return _c(VTimelineItem, {
            key: entry.lfdnr
          }, [_c(VCard, [_c(VCardTitle, [_vm._v(_vm._s(entry.title) + " ")]), _c(VCardSubtitle, [_vm._v(" " + _vm._s(entry.userId) + " am " + _vm._s(_vm.dateTimeService.getGermanDateFormat(entry.date)) + " ")]), _c(VCardText, [_vm._v(_vm._s(entry.description))])], 1)], 1);
        }), 1) : _c('div', _vm._l(_vm.history, function (entry) {
          return _c(VCard, {
            key: entry.lfdnr,
            staticClass: "my-4"
          }, [_c(VCardTitle, [_vm._v(_vm._s(entry.title) + " ")]), _c(VCardSubtitle, [_vm._v(_vm._s(entry.userId) + " am " + _vm._s(_vm.dateTimeService.getGermanDateFormat(entry.date)))]), _c(VCardText, [_vm._v(_vm._s(entry.description))])], 1);
        }), 1)], 1) : _c('div', [_vm._v(" Für diesen Task ist kein Verlauf verfügbar ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              _vm.taskHistory = false;
            }
          }
        }, [_vm._v(" OK ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.taskHistory,
      callback: function ($$v) {
        _vm.taskHistory = $$v;
      },
      expression: "taskHistory"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };